import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { REACT_APP_API_ENDPOINT } from '../config/aws-config';
import { jwtDecode } from 'jwt-decode';
import { Form, Modal, Spinner } from 'react-bootstrap'
import thumbImg from "../images/view-update-img.png"
import { getBase64 } from '../utils/s3/uploadImage';
import { v4 as uuidv4 } from 'uuid';
import { io } from 'socket.io-client'

let socket = io(process.env.REACT_APP_SOCKET_ENDPOINT);

const ViewRequest = () => {
    const navigate = useNavigate();
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const token = params.get('token');

    const [applicationData, setApplicationData] = useState({})
    const [loading, setLoading] = useState(true)
    const [approveLoading, setApproveLoading] = useState(false)
    const [show, setShow] = useState(false);
    const [review, setReview] = useState('');
    const [reviewLoading, setReviewLoading] = useState(false);
    const [isReviewValid, setIsReviewValid] = useState(true);
    const [picture, setPicture] = useState();
    const [userId, setUserId] = useState();
    const [modalID, setModalID] = useState('');

    useEffect(() => {
        if(token) {
            try {
                const decodedToken = jwtDecode(token);
                fetchData(decodedToken?.id)
                setUserId(decodedToken?.id)
            } catch(error) {
                console.error('Error decoding JWT token:', error.message);
                navigate("/")
            }
        } else {
            navigate("/")
        }
    }, [token]);

    useEffect(() => {
        const roomName = uuidv4()
        socket.emit('joinRoom', roomName);
        setModalID(roomName)

        socket.on('message', async (message) => {
            if(applicationData?.id) {
                handleMainSubmit(message[0])
            }
        });

    }, [picture]);


    const fetchData = (id) => {
        setLoading(true)
        axios.get(`${REACT_APP_API_ENDPOINT}${id}`)
            .then(function (response) {
                setApplicationData(response.data.data.Item)
                setLoading(false)
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false)
            });
    }

    const handleApprove = () => {
        setApproveLoading(true)
        axios.put(REACT_APP_API_ENDPOINT, { ...applicationData, customerReviews: "", approve: true })
            .then(function (response) {
                setShow(false)
                setApproveLoading(false)
                fetchData(applicationData.id)
            })
            .catch(function (error) {
                console.log(error);
                setApproveLoading(false)
            });
    }

    const handleReviewChange = (event) => {
        const value = event.target.value;
        setReview(value);
        validateReview(value);
    };

    const validateReview = (value) => {
        setIsReviewValid(value.trim().length > 0);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if(review.trim().length > 0) {
            if(!picture) {
                setReviewLoading(true)
                axios.put(`${REACT_APP_API_ENDPOINT}?isReview=true`, { ...applicationData, customerReviews: [...applicationData.customerReviews, { review: review, reviewImage: '' }] })
                    .then(function (response) {
                        fetchData(applicationData.id)
                        setReviewLoading(false)
                        setReview('')
                    })
                    .catch(function (error) {
                        console.log(error);
                        setReviewLoading(false)
                    });
            } else {
                getSocketData(picture)
            }
        } else {
            setIsReviewValid(false)
        }
    };

    const handleMainSubmit = async (reviewImage) => {
        setReviewLoading(true)
        await axios.put(`${REACT_APP_API_ENDPOINT}?isReview=true`, { ...applicationData, customerReviews: [...applicationData.customerReviews, { review: review, reviewImage: reviewImage }] })
            .then(function (response) {
                fetchData(response?.data?.data?.id)
                setReviewLoading(false)
                setReview('')
                handleRemoveImage()
            })
            .catch(function (error) {
                console.log(error);
                setReviewLoading(false)
            });
    }

    const getSocketData = (picture) => {
        socket.emit('joinRoom', modalID);
        socket.emit('sendMessage', { roomName: modalID, message: [picture] });
    }

    const handleRemoveImage = () => {
        setPicture()
    }

    const handleImage = (event) => {
        const file = event.target.files[0];

        if(file) {
            getBase64(file)
                .then((base64String) => {
                    setPicture({ image: base64String, path: `${userId}/${new Date().getTime()}`, isUpload: true });
                })
                .catch((error) => {
                    console.error('Error converting image to base64:', error);
                });
        }
    }

    const handlePayment = () => {
        let data = {
            carat: applicationData.carat,
            metal: applicationData.metal,
            metalColor: applicationData.metalColor,
            picture: applicationData.picture,
            renderings: applicationData.renderings,
            ringEngraving: applicationData.ringEngraving,
            ringSize: applicationData.ringSize,
            shape: applicationData.shape,
            tellUs: applicationData.tellUs,
            url: applicationData.url,
            id: applicationData.id,
            price: applicationData.price
        }
        window.parent.postMessage(JSON.stringify(data), '*');
    }

    return (
        <section className='request-view-section pt-5'>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Approve</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure approve this design?</Modal.Body>
                <Modal.Footer>
                    <button type='button' className='back-btn px-3 ms-1' onClick={() => setShow(false)}>No</button>
                    <button type='button' disabled={approveLoading} className='btn-small px-3 ms-1' onClick={handleApprove}>
                        {approveLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : ''}
                        Yes</button>
                </Modal.Footer>
            </Modal>

            {loading ?
                <h3 className='request-view-title mb-0 container small-container'>Loading...</h3>
                : <div className='container small-container'>
                    <div className='d-flex flex-wrap align-items-center heading-wrap mb-xl-5 mb-4'>
                        <h3 className='request-view-title mb-0'>Your Request's Details</h3>
                        <div>
                            {applicationData && !applicationData?.approve ? applicationData?.renderings?.length ? <button type='button' className='btn-small px-3 approve-btn' onClick={() => setShow(true)}>Approve Design</button> : '' : ''}
                            {applicationData && applicationData?.approve ? !applicationData?.amountPaid ?
                                applicationData?.price?.retail ? <button type='button' className='btn-small px-3 approve-btn ms-1' onClick={handlePayment}>Approve & Pay</button> : ''
                                : <button type='button' className='btn-small px-3 approve-btn ms-1' disabled>Paid</button>
                                : ''
                            }
                        </div>
                    </div>
                    {applicationData &&
                        <>
                            <div className='mb-3 row'>
                                <div className='col-12 mb-2'>
                                    {applicationData?.price?.retail && <p className='mb-2'>
                                        <span className='ring-section-title fw-bolder'>Price :- </span>
                                        <span className='fw-bolder ring-section-title'>$ {applicationData?.price?.retail}</span>
                                    </p>}
                                </div>
                                <div className='col-md-6'>
                                    <h3 className='ring-section-title fw-bolder mb-3'>Customer details</h3>
                                    <p className='mb-2'>
                                        <span className='app-sub-title'>First Name :- </span>
                                        <span>{applicationData?.firstName}</span>
                                    </p>
                                    <p className='mb-2'>
                                        <span className='app-sub-title'>Last Name :- </span>
                                        <span>{applicationData?.lastName}</span>
                                    </p>
                                    <p className='mb-2'>
                                        <span className='app-sub-title'>Email ID :- </span>
                                        <span>{applicationData?.email}</span>
                                    </p>
                                    <p className='mb-2'>
                                        <span className='app-sub-title'>Mobile Number :- </span>
                                        <span>{applicationData?.phoneNumber}</span>
                                    </p>
                                    <p className='mb-2'>
                                        <span className='app-sub-title'>Reason :- </span>
                                        <span>{applicationData?.reason}</span>
                                    </p>
                                    <p className='mb-2'>
                                        <span className='app-sub-title'>Date :- </span>
                                        <span>{applicationData?.date}</span>
                                    </p>
                                </div>

                                <div className='col-md-6 mt-md-0 mt-4'>
                                    <h3 className='ring-section-title fw-bolder mb-3'>Ring details</h3>
                                    <p className='mb-2'>
                                        <span className='app-sub-title'>Metal :- </span>
                                        <span>{applicationData?.metal}</span>
                                    </p>
                                    <p className='mb-2'>
                                        <span className='app-sub-title'>Metal Color :- </span>
                                        <span>{applicationData?.metalColor}</span>
                                    </p>
                                    <p className='mb-2'>
                                        <span className='app-sub-title'>Ring Size :- </span>
                                        <span>{applicationData?.ringSize}</span>
                                    </p>
                                    <p className='mb-2'>
                                        <span className='app-sub-title'>Ring engraving :- </span>
                                        <span>{applicationData?.ringEngraving}</span>
                                    </p>
                                    <p className='mb-2'>
                                        <span className='app-sub-title'>Ring Shape :- </span>
                                        <span>{applicationData?.shape}</span>
                                    </p>
                                    <p className='mb-2'>
                                        <span className='app-sub-title'>Carat :- </span>
                                        <span>{applicationData?.carat}</span>
                                    </p>
                                </div>

                            </div>
                            <p className='mb-4'>
                                <span className='app-sub-title'>Tell us :- </span>
                                <span>{applicationData?.tellUs}</span>
                            </p>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className='border p-3 ring-image-view'>
                                        <p className='mb-1 app-sub-title'>Ring images:-</p>
                                        <div className='d-flex flex-wrap ring-image-list'>
                                            {
                                                applicationData?.picture?.map((data, i) => {
                                                    return (
                                                        <a target='_blank' key={i} href={data}>
                                                            <img src={data} alt='image' />
                                                        </a>
                                                    )
                                                })
                                            }
                                            {applicationData?.url &&
                                                <a target='_blank' href={applicationData?.url}>
                                                    <img src={applicationData?.url} alt='image' />
                                                </a>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {applicationData?.renderings?.length !== 0 ? <div className='col-lg-6 mt-lg-0 mt-3'>
                                    <div className='border p-3 ring-image-view'>
                                        <p className='mb-1 app-sub-title'>Renderings images:-</p>
                                        <div className='d-flex flex-wrap ring-image-list'>
                                            {
                                                applicationData?.renderings?.map((data, i) => {
                                                    return (
                                                        <a target='_blank' key={i} href={data}>
                                                            <img src={data} alt='image' />
                                                        </a>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div> : ''}
                            </div>
                            <div className='pt-5'>
                                {applicationData?.customerReviews?.length !== 0 &&
                                    <>
                                        <h3 className='ring-section-title fw-bolder mb-3'>Your Reviews</h3>
                                        <ul className='mb-4 review-list'>
                                            {
                                                applicationData?.customerReviews?.map((data, i) => {
                                                    return <li key={i} className='mb-3'>
                                                        <span className='d-flex'>
                                                            {data.review}
                                                        </span>
                                                        {
                                                            data?.reviewImage && <img src={data?.reviewImage} alt={data.review} style={{ maxWidth: "100px" }} />
                                                        }
                                                    </li>
                                                })
                                            }
                                        </ul>
                                    </>
                                }
                            </div>
                        </>
                    }

                    {!applicationData?.approve ? <Form className='pb-5' onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="review">
                            <h3 className='ring-section-title fw-bolder mb-3'>Reviews</h3>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                name="review"
                                placeholder="Enter text..."
                                value={review}
                                onChange={handleReviewChange}
                                isInvalid={!isReviewValid}
                                style={{ lineHeight: '1.4' }}
                            />
                            <Form.Control.Feedback type="invalid">
                                Review cannot be empty.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <div className='row col-lg-5 col-md-7 col-12 mb-4'>
                            <span className="d-block w-100 mt-1 col-12 view-text">Review Image</span>
                            <div className='col-6'>
                                <div className='position-relative upload-view-thumb'>
                                    <img
                                        src={typeof picture === "string" ? picture : picture?.image ? picture?.image : thumbImg}
                                        className='w-100'
                                    />
                                    <a className='upload-close-img d-block' onClick={handleRemoveImage}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                            <path d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z" fill="#0F0F0F" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                            <div className='col-6'>
                                <Form.Group className="position-relative mb-3 upload-file h-100" controlId="picture">
                                    <Form.Label className='picture-label mb-0 w-100 d-flex align-items-center h-100'>
                                        <div className='w-100'>
                                            <span className='plus-icon d-flex align-items-center justify-content-center mx-auto'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                                    <path d="M4 12H20M12 4V20" stroke="#999" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </span>
                                            <span className="d-block upload-label-text text-center mt-2">Upload a picture</span>
                                        </div>
                                    </Form.Label>
                                    <Form.Control
                                        type="file"
                                        name="picture"
                                        // disabled={mainData?.picture?.length >= 2}
                                        onChange={(e) => handleImage(e)}
                                    />
                                </Form.Group>
                            </div>
                        </div>


                        <button type='submit' className='site-btn btn'>
                            {reviewLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : ''}
                            Submit Review
                        </button>
                    </Form> : ''}

                </div>}
        </section>
    )
}

export default ViewRequest